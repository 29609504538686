export function API_URL() {
   // return import.meta.env.API_HOST;
  //return process.env.REACT_APP_API_HOST;
   return process.env.REACT_APP_API_URL || "https://api.preprod.paytaps.com/api/"
 }

export function API_HOST() {
   // return import.meta.env.API_HOST;
  //return process.env.REACT_APP_API_HOST;
  return process.env.REACT_APP_API_HOST || "https://api.preprod.paytaps.com/"
}
