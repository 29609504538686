import React, { useState, useEffect, useCallback, useRef, memo } from 'react' 
import './CSS/topup.css'
import { useToggle } from '../context/ToggleContext'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button' 
import { convertToUSDT, requestTopUp, refreshToken, killToken, saveToken, userBalance } from '../security/AuthService' 
import { useNavigate } from 'react-router-dom' 
import { useActive } from '../context/ActiveContext' 

const Topup = memo((props) => {
    const [amount, setAmount] = useState('--')
    const [totalAmount, setTotalAmount] = useState('--') 
    const navigate = useNavigate() 
    const { handleMenuActive } = useActive()  

    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value) 
        state.setFieldTouched(e.target['name'])
    }

    useEffect(()=>{
        try {
            if(document.getElementById('topup_amount')) {
                document.getElementById('topup_amount').addEventListener('keyup', ()=>{
                    handleChange() 
                })}
        } catch(err) {

        }

    } )       
    async function handleChange() {
        
       try {
        
            let topup_fee = parseFloat(localStorage.getItem('t_u_f'))
            if(document.getElementById('topup_amount').value === '') {
                localStorage.setItem('t_u_f', 0)   
                setAmount('--')
                setTotalAmount('--') 
            }
            if( /^[0-9\.]+$/.test(document.getElementById('topup_amount').value)) {
                
                    let convert = await convertToUSDT({
                        amount: parseFloat(document.getElementById('topup_amount').value) 
                    }) 

                    // If fetch returns 401 
                    if(convert.status === 401) {
                        const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                        // If refresh token returns 401
                        if(refreshTokenResult.status === 401) {
                        killToken()
                        return navigate('/login')
                        } 

                        // If refresh token is successfull 
                        const payloadRefreshToken = await refreshTokenResult.json()   

                        let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                        // Payload of new generated token 
                        let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                        if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                        killToken()
                        return navigate('/login') 
                        } else {
                        saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
                        convert = await convertToUSDT({
                            amount: parseFloat(document.getElementById('topup_amount').value) 
                        }) 
                        }

                    }

                    const convert_data = await convert.json() 

                    if(document.getElementById('topup_amount').value === '') {
                        setAmount('--')
                        setTotalAmount('--') 
                    } else {
                        if(document.getElementById('topup_amount').value >= 100) { 
                            localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_100')) 
                            topup_fee = parseFloat(localStorage.getItem('t_u_f')) 
                        } else if((document.getElementById('topup_amount').value >= 50) && (document.getElementById('topup_amount').value < 100)) {
                            localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_50_100'))  
                            topup_fee = parseFloat(localStorage.getItem('t_u_f'))  
                        }
                        setAmount((parseFloat(document.getElementById('topup_amount').value))) 
                        setTotalAmount((((parseFloat(convert_data['data']['result'])*topup_fee)/100) + parseFloat(convert_data['data']['result'])))  
                                          
                           
                    }
            } else {
                localStorage.setItem('t_u_f', 0)   
                setAmount('--')
                setTotalAmount('--')
            } 
       } catch(err) {
       }
    } 
    const handleCancel = useCallback(function() {
        let path = window.location.pathname;
        path = path.split("/");
        path = path[path.length - 1] 

        if(path === 'topupcard') {
            navigate('/mycard')   
            handleMenuActive('My Card')    
            handleMenuActive('My Card')      

        }
        setAmount('--')
        setTotalAmount('--')
        props.handleClose()

    }, [])  
  return (
     <Modal show={props.show} onHide={props.handleClose} className='topup-modal' backdrop='static' keyboard='false' >
        <div className='modal-header'>
            <div className='topup-title'>Top-up</div>
            <div className='topup-description'>Enter the amount in USD you want to add. This amount will be available on your card in USD.</div>
        </div> 
    
        <Formik
                initialValues={{
                    topup_amount: ''
                }}
                validateOnChange={ true }
                validateOnBlur={ true } 
                validationSchema={YUP.object({
                    topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid top-up amount</span>}).test('topup', `Please enter at least ${50}`,  (value, context) =>(parseInt(value))>=50),   
                    // topup_amount: ''
                })}

                onSubmit={async (values)=>{ 
                    try {
                        let path = window.location.pathname;
                        path = path.split("/");
                        path = path[path.length - 1]   
                        
                        let topup = await requestTopUp({
                                amountFiat: parseFloat(values.topup_amount), 
                                amountCryptoTotal: parseFloat(totalAmount)
                        }) 
                        // If fetch returns 401 
                        if(topup.status === 401) {
                            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                            // If refresh token returns 401
                            if(refreshTokenResult.status === 401) {
                            killToken()
                            return navigate('/login')
                            } 

                            // If refresh token is successfull 
                            const payloadRefreshToken = await refreshTokenResult.json()   

                            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                            // Payload of new generated token 
                            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                            killToken()
                            return navigate('/login') 
                            } else {
                            saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)  
                             
                            topup = await requestTopUp({
                                amountFiat: parseFloat(values.topup_amount), 
                                amountCryptoTotal: parseFloat(totalAmount)
                            }) 
                            }

                        }

                        if(topup.ok) {
                            const result = await topup.json()   
                            if(path === 'topupcard') {
                                       
                                props.handleClose() 
                            } 
                            if(path === 'mycard') {
                                props.handleClose()
                                props.updateBalance(result['data']['balance']) 
                            }
                            if(path === 'dashboard') {
                                props.handleClose()   
                                props.updateBalance(result['data']['balance'])       
                            }
                            props.handleShowTopupSuccess() 
                        }

                        if(!topup.ok) {
                            props.handleClose()
                            props.handleShowInsufTopupBalance()  
                        }
                        setAmount('--')
                        setTotalAmount('--') 
                                    
                        } catch(err) {
                        }
                }}
                        >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                <TextInput 
                                    name='topup_amount' 
                                    id='topup_amount' 
                                    label='Enter amount' 
                                    inputtype='text' 
                                    onKeyUp={keyUpHandler} 
                                    currency='usd'  
                                />   
                                <div className='amount-fees-wrapper'> 
                                    <div className='amount-fees-row'><span>Available balance</span><span>{`${props.balanceUSDT.toFixed(2)}`} <strong>USDT</strong> | {props.balanceUsd.toFixed(2)} <strong>USD</strong></span></div>                                      
                                    <div className='amount-fees-row'><span>Amount</span><span>{!isNaN(parseFloat(amount)) ? parseFloat(amount).toFixed(2) : '--'} USD</span></div>
                                    <div className='amount-fees-row'><span>Fees</span><span>{localStorage.getItem('t_u_f')}%</span></div> 
                                    <div className='amount-fees-row'><span>Total</span><span>{!isNaN(parseFloat(totalAmount)) ? parseFloat(totalAmount).toFixed(2) : '--'} USDT</span></div> 
                                </div>
                                <div className='transaction-fee-notification'>
                                    * Our transaction fee are included. <span>See transaction fee</span>
                                </div>
                                <div className='group-btn'>
                                    <Button click={handleCancel} id="cancel">Cancel</Button>
                                    <Button btnType='submit' id='confirm_topup' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting }>Confirm</Button>     
                                </div>
                                    
                            </Form>)}}
        </Formik> 
   </Modal> 
  )
}
) 

export default Topup 
